import React, { useEffect, useState } from 'react';
import styles from './ArticlesList.module.css'; // Import CSS module for styling
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService/api';

const ArticlesList = () => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    getAllArticles();
  }, []);

  const getAllArticles = async() => {
    try {
      
      const response = await ApiService.getData('article-jobs');

      setArticles(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleArticleClick = (articleId) => {
    navigate('/builder/' + articleId);
  };

  const handleNewArticle = () => {
    navigate('/builder');
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Job List</h1>
        <button className={styles.newArticleBtn} onClick={handleNewArticle}>
          New Article Job
        </button>
      </div>
      
      <div className={styles.articlelist}>
        {articles.map((article) => (
          <div key={article.id} className={styles.articleName} 
          onClick={() => handleArticleClick(article.id)} >
            {article.jobname}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticlesList;
