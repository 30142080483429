import React, { useEffect, useState } from 'react';
import styles from './Article.module.css'; // Import CSS module
import { useParams, useNavigate } from 'react-router-dom';
import JSZip from 'jszip';
import ApiService from '../../services/apiService/api';

const Article = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(''); 

  useEffect(() => {
    getAllArticles();
  }, [id]);

  const getAllArticles = async() => {
    try {
      const response = await ApiService.getDataById('articles', id);
      setArticle(response);

      // for(let i = 0; i < response.data.length; i++) {
      //   if(response.data[i].id == id) {
      //     setSelectedTopic(response.data[i].topic);
      //     break
      //   }
      // }

    } catch (error) {
      console.error(error);
    }
  };
  

  // Function to handle topic change
  const handleTopicChange = (event) => {
    setSelectedTopic(event.target.value);
  };

  const downloadArticle = () => {
    // const article = article.find(a => a.topic === selectedTopic);
    const content = article.article.map(section => `${section.title}\n${section.content}\n\n`).join('');

    const element = document.createElement('a');
    const file = new Blob([content], { type: 'text/plain' });

    element.href = URL.createObjectURL(file);
    element.download = `${article.topic}.txt`;
    document.body.appendChild(element); 
    element.click(); 
  };

  const downloadAllArticlesAsZip = () => {
    const zip = new JSZip();
    // articles.forEach((article, index) => {
    //   const content = article.article.map(section => `${section.head}\n${section.body}\n\n`).join('');
    //   zip.file(`${article.topic}.txt`, content);
    // });
  
    zip.generateAsync({ type: 'blob' }).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'all_articles.zip';
  
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <div className={styles.articleContainer}>
        <div className={styles.header}>
            <h1 onClick={() => navigate('/') } style={{ cursor: 'pointer' }}>Article</h1>
        
            {/* <div className={styles.subheader}>
            <button className={styles.downloadBtn} onClick={downloadAllArticlesAsZip}>
              Download All
            </button>

              <select value={selectedTopic} onChange={handleTopicChange} className={styles.select}>
                  <option value="">Select Topic</option>
                  {articles.map((article) => (
                      <option key={article.id} value={article.topic}>
                          {article.topic}
                      </option>
                  ))}
              </select>
            </div> */}
           
        </div>
      
      <div className={styles.selectedArticle}>
          <div className={styles.header}>
            <h2>{article? article.topic: ""}</h2>
            <button style={{ backgroundColor: 'green', color: 'white' }} onClick={downloadArticle} >Download File</button>
          </div>

          {article.article ? article.article.map((item, index) => (
              <div key={index}>
                  <h3>{item.title ? item.title : ""}</h3>
                  <p>{item.content}</p>
              </div>
          )): "No article found"}

          {/* <div>
              <div className={styles.header}>
                <h2>{article.topic}</h2>
                <button style={{ backgroundColor: 'green', color: 'white' }} >Download File</button>
              </div>
              {article.article.map((item, index) => (
                  <div key={index}>
                      <h3>{item.title ? item.title : ""}</h3>
                      <p>{item.content}</p>
                  </div>
              ))}
          </div> */}
        
      </div>
    </div>
  );
};

export default Article;

