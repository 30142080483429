// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.Article_articleContainer__mCl8e {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Times New Roman', Times, serif;
  }

  .Article_header__T4b\\+m {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .Article_subheader__JJ2au {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .Article_select__ITka9 {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc
  }
  
  .Article_selectedArticle__YdWRp {
    border: 1px solid #ccc;
    padding: 10px;
  }

  .Article_downloadBtn__LWEl- {
    background-color: gray;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/components/article/Article.module.css"],"names":[],"mappings":";;AAEA;IACI,UAAU;IACV,cAAc;IACd,aAAa;IACb,4CAA4C;EAC9C;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB;EACF;;EAEA;IACE,sBAAsB;IACtB,aAAa;EACf;;EAEA;IACE,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,mBAAmB;EACrB","sourcesContent":["\n\n.articleContainer {\n    width: 80%;\n    margin: 0 auto;\n    padding: 20px;\n    font-family: 'Times New Roman', Times, serif;\n  }\n\n  .header {\n    display: flex;\n    justify-content: center;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 20px;\n  }\n\n  .subheader {\n    display: flex;\n    justify-content: center;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 20px;\n  }\n\n  .select {\n    padding: 10px;\n    font-size: 16px;\n    margin-bottom: 20px;\n    border-radius: 5px;\n    border: 1px solid #ccc\n  }\n  \n  .selectedArticle {\n    border: 1px solid #ccc;\n    padding: 10px;\n  }\n\n  .downloadBtn {\n    background-color: gray;\n    color: #fff;\n    border: none;\n    padding: 10px 20px;\n    font-size: 16px;\n    cursor: pointer;\n    margin-bottom: 20px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"articleContainer": `Article_articleContainer__mCl8e`,
	"header": `Article_header__T4b+m`,
	"subheader": `Article_subheader__JJ2au`,
	"select": `Article_select__ITka9`,
	"selectedArticle": `Article_selectedArticle__YdWRp`,
	"downloadBtn": `Article_downloadBtn__LWEl-`
};
export default ___CSS_LOADER_EXPORT___;
