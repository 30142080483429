import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Builder from './components/builder/Builder';
import PSBuilder from './components/psbuilder/PSBuilder';
import ArticlesList from './components/articleslist/ArticlesList';
import Article from './components/article/Article';


const App = () => {
  return (
    <Router>
      <Routes>
       <Route path="/" element={<ArticlesList />} />
        <Route path="/builder" element={<Builder />} />
        <Route path="/builder/:id" element={<Builder />} />
        <Route path="/articles/:id" element={<Article />} />
        <Route path="/psbuilder" element={<PSBuilder />} />
      </Routes>
    </Router>
  );
}

export default App;
