import React, { useEffect, useState } from 'react';
import styles from './Builder.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import ApiService from '../../services/apiService/api';

const Builder = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [jobname, setJobname] = useState('');
    const [topic, setTopic] = useState('');
    const [maxSize, setMaxSize] = useState('');
    const [product, setProduct] = useState('');
    const [audience, setAudience] = useState('');
    const [feature, setFeature] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [builder, setBuilder] = useState([]);
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        getAllPSBuilders();
    }, []);

    useEffect(() => {
        if (id) {
            getArticleJobById();
        }
    }, [id, builder]);

    useEffect(() => {
        if(id) {
            getArticlesByJobId();
        }
    }, [id]);

    const getArticleJobById = async () => {
        try {
            const response = await ApiService.getDataById('article-jobs', id);
            setJobname(response.jobname);
            setTopic(response.topic.join('\n'));
            setMaxSize(response.maxSize);
            setProduct(response.product);
            setAudience(response.audience);
            setFeature(response.feature);
            setSelectedOption(builder.find(b => b.id == response.psbuilder_id).name);
        } catch (error) {
            console.error(error);
        }
    };

    const generateArticles = async() => {
        try {            
            const response = await ApiService.getDataById('generate-article', id);

            if(response) {
                console.log('Articles generated successfully', response);
                getArticlesByJobId();
            }
        } catch (error) {
          console.error(error);
        }
    };

    const getArticlesByJobId = async() => {
        try {
            const response = await ApiService.getDataById('articles/all', id);
            setArticles(response);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSave = async() => {
        if(topic === '' || selectedOption === '') {
            alert('Please fill all the fields');
            return;
        } else{
            const newTopic = topic.split('\n');
            const data = {
                jobname,
                topic: newTopic,
                maxSize,
                product,
                audience,
                feature,
                psbuilder_id: builder.find(b => b.name === selectedOption).id,
            };
    
            try {
                // const response = await axios.post('http://localhost:5000/article-jobs', data);
                const response = await ApiService.storeData('article-jobs', data);
                console.log(response.message);
                navigate('/');
            } catch (error) {
                console.error(error);
            }

        }
    };

    const handleUpdate = async() => {
        if(topic === '' || selectedOption === '') {
            alert('Please fill all the fields');
            return;
        } else{
            const newTopic = topic.split('\n');
            const data = {
                jobname,
                topic: newTopic,
                maxSize,
                product,
                audience,
                feature,
                psbuilder_id: builder.find(b => b.name === selectedOption).id,
            };
    
            try {
                // const response = await axios.put(`http://localhost:5000/article-jobs/${id}`, data);
                const response = await ApiService.updateData('article-jobs', id, data);
                console.log(response.message);
                // navigate('/');
            } catch (error) {
                console.error(error);
            }

        }
    };

    const getAllPSBuilders = async () => {
        try {
            // const response = await axios.get('http://localhost:5000/psBuilders');
            const response = await ApiService.getData('psBuilders');
            setBuilder(response);
        } catch (error) {
            console.error(error);
        }
    };

    const handleInput = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case 'jobname':
                setJobname(value);
                break;
            case 'topic':
                setTopic(value);
                break;
            case 'maxSize':
                setMaxSize(value);
                break;
            case 'product':
                setProduct(value);
                break;
            case 'audience':
                setAudience(value);
                break;
            case 'feature':
                setFeature(value);
                break;
            default:
                break;
        }
    };

    const handlePSBuilder = () => {
        navigate('/psbuilder');
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1 onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>Article Job Builder</h1>

                <button className={styles.AddBtn} onClick={handlePSBuilder}>New PS Builder</button>
            </div>
            
            <table>
               <tbody>
                <tr>
                    <td colSpan={2}><input type="text" name='jobname' value={jobname} onChange={handleInput} placeholder="Job name" /></td>
                </tr>
                <tr>

                    <td rowSpan={3}><textarea name='topic' rows={6} value={topic} onChange={handleInput} placeholder='Topic'></textarea></td>
                    <td><input type="text" name='maxSize' value={maxSize} onChange={handleInput} placeholder="Max size" /></td>
                </tr>
        
                <tr>
                    <td><input type="text" name='product' value={product} onChange={handleInput} placeholder="Product" /></td>
                </tr>
              
                <tr>
                    <td><input type="text" name='audience' value={audience} onChange={handleInput} placeholder="Audience" /></td>
                </tr>

                <tr>
                    <td colSpan={2}><textarea name='feature' rows={3} value={feature} onChange={handleInput} placeholder='Feature'></textarea></td>
                </tr>

                <tr>
                    <td>
                        <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                            <option value="">Select Builder</option>
                            {builder.map((b) => (
                                <option key={b.id} value={b.name}>
                                    {b.name}
                                </option>
                            ))}
                        </select>
                    </td>
                    <td>
                        { id ? <button className={styles.saveBtn} onClick={handleUpdate}>Update</button> : <button className={styles.saveBtn} onClick={handleSave}>Save</button>}
                    </td>
                </tr>
                <tr>
                    {id && <td colSpan={2}><button className={styles.generateBtn} onClick={generateArticles}>Generate New Articles</button></td>}
                </tr>
               
                </tbody>
                
            </table>

            {articles.length > 0 && (<div className={styles.subcontainer}> 
                <h2>Already Generated Articles</h2>
                <div className={styles.articlelist}>
                    {articles.map((article) => (
                    <div key={article.id} className={styles.articleName} 
                        onClick={() => navigate(`/articles/${article.id}`)}
                    >
                        {article.topic}
                    </div>
                    ))}
                </div>
            </div>)}
        </div>
    );
};

export default Builder;
