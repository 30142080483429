import React, { useState } from 'react';
import styles from './PSBuilder.module.css';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService/api';

const PSBuilder = () => {
    const navigate = useNavigate();
    const [builderName, setBuilderName] = useState('');
    const [rows, setRows] = useState([
        { number: "1", keyword: "Outline", prompt: "generate a detailed outline for a {maxSize} blog post article about '{topic}' that will be read by people who like to {audience}. At about 80% of the article, introduce my product called '{product}' that has the following benefits : {feature} ", size: "" },
        { number: "2", keyword: "Intro", prompt: "From the article outline, write an introduction...", size: "150" },
        { number: "3", keyword: "Info", prompt: "From the article outline, write a section ...", size: "300" },
        { number: "4", keyword: "Promo", prompt: "From the article outline, write a promotion section ...", size: "400" },
        { number: "6", keyword: "Conclusion", prompt: "Write a conclusion for this article", size: "200" }
    ]);

    const handleSave = async () => {
        try {
            const data = {
                name: builderName,
                table: rows
            };
    
            const response = await ApiService.storeData('psBuilders', data);
            console.log(response.message);
            navigate(-1);
        } catch (error) {
            // Handle errors if the request fails
            console.error('Error saving data:', error);
        }
    };
    
    const handleInputChange = (e) => {
        setBuilderName(e.target.value);
    };

    const handleRowChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
    };

    return (
        <div className={styles.psBuilderContainer}>
            <div className={styles.header}>
                <h1>Prompt Sequence Builder</h1>
                <button onClick={() => navigate(-1)}>Go Back</button>
            </div>
           
            <form>
                <input type="text" name="psbname" value={builderName} onChange={handleInputChange} placeholder='Builder Name' />
            </form>
            <div className={styles.tableContainer}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>KEYWORDS</th>
                            <th>PROMPT</th>
                            <th>SIZE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={index}
                                index={index}
                                number={row.number}
                                keyword={row.keyword}
                                prompt={row.prompt}
                                size={row.size}
                                handleRowChange={handleRowChange}
                            />
                        ))}
                    </tbody>
                </table>
                <button onClick={handleSave}>Save</button>
            </div>
        </div>
    );
};

const TableRow = ({ index, number, keyword, prompt, size, handleRowChange }) => {
    const handleChange = (e, field) => {
        handleRowChange(index, field, e.target.value);
    };

    return (
        <tr>
            <td style={{ width: '5%' }}>{number}</td>
            <td style={{ width: '15%', textAlign: 'center' }}>{keyword}</td>
            <td style={{ width: '60%' }}><textarea value={prompt} onChange={(e) => handleChange(e, 'prompt')}></textarea></td>
            <td style={{ width: '20%' }}><input type="number" value={size} onChange={(e) => handleChange(e, 'size')} /></td>
        </tr>
    );
};

export default PSBuilder;
