import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL; 

// Function to handle API requests using Axios
const api = async (url, method = 'GET', data = null) => {
  try {
    const response = await axios({
      method,
      url: `${BASE_URL}/${url}`,
      headers: {
        'Content-Type': 'application/json',
        // You can add more headers here if needed
      },
      data,
    });

    return response.data;
  } catch (error) {
    console.error('API Error:', error.message);
    throw error;
  }
};

// Service functions for various API calls
const ApiService = {
  getData: async (endpoint) => {
    try {
      const response = await api(endpoint);
      return response;
    } catch (error) {
      throw error;
    }
  },

  getDataById: async (endpoint, id) => {
    try {
      const response = await api(`${endpoint}/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  storeData: async (endpoint, data) => {
    try {
      const response = await api(endpoint, 'POST', data);
      return response;
    } catch (error) {
      throw error;
    }
  },

  updateData: async (endpoint, id, data) => {
    try {
      const response = await api(`${endpoint}/${id}`, 'PUT', data);
      return response;
    } catch (error) {
      throw error;
    }
  },

  deletePost: async (endpoint, id) => {
    try {
      const response = await api(`${endpoint}/${id}`, 'DELETE');
      return response;
    } catch (error) {
      throw error;
    }
  },

};

export default ApiService;
