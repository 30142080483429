// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArticlesList_container__g7of1 {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.ArticlesList_header__-z\\+B\\+ {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.ArticlesList_new-article-btn__dywuX {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
}

.ArticlesList_new-article-btn__dywuX:hover {
  background-color: #0056b3;
}

.ArticlesList_articleName__Dl3zi {
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.ArticlesList_articleName__Dl3zi:hover {
  background-color: #f0f0f0;
}
`, "",{"version":3,"sources":["webpack://./src/components/articleslist/ArticlesList.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,cAAc;EACd,aAAa;AACf;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;EACf,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".container {\n  width: 80%;\n  margin: 0 auto;\n  padding: 20px;\n}\n\nh1 {\n  font-size: 24px;\n  margin-bottom: 20px;\n}\n\n.header {\n  display: flex;\n  justify-content: center;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.new-article-btn {\n  background-color: #007bff;\n  color: #fff;\n  border: none;\n  padding: 10px 20px;\n  font-size: 16px;\n  cursor: pointer;\n  margin-bottom: 20px;\n}\n\n.new-article-btn:hover {\n  background-color: #0056b3;\n}\n\n.articleName {\n  padding: 10px;\n  border: 1px solid #ccc;\n  margin-bottom: 10px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n  border-radius: 5px;\n}\n\n.articleName:hover {\n  background-color: #f0f0f0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ArticlesList_container__g7of1`,
	"header": `ArticlesList_header__-z+B+`,
	"new-article-btn": `ArticlesList_new-article-btn__dywuX`,
	"articleName": `ArticlesList_articleName__Dl3zi`
};
export default ___CSS_LOADER_EXPORT___;
